/* new vellum */
.mv_bi_nv.header {
	color: #2C2929FF;
	text-shadow: 2px 2px #F5EEC1;
	font-family: "Spectral";
}
.mv_bi_nv.header > div.summary {
	font-family: "Spectral";
}

.mv_bi_nv .title-fill > div {
	background-color: #F5EEC1;
}

.mv_bi_nv .title-content {
	border-color: #201C1B;
	border-style: solid;
	border-width: 2px;
}

.mv_bi_nv .title-content, .mv_bi_nv .title-shadow, .mv_bi_nv .title-highlight, .mv_bi_nv .title-fill,
.mv_bi_nv .title-content > div, .mv_bi_nv .title-shadow > div, .mv_bi_nv .title-highlight > div, .mv_bi_nv .title-fill > div {
	border-radius: 5px;
}

.mv_bi_nv .title-shadow {
	display: none;
}

.mv_bi_nv .title-highlight {
	display: none;
}

.mv_bi_nv .title-block,
.mv_bi_nv .title-block a {
	font-family: "Spectral";
	color: #571B0CFF
}

.mv_bi_nv .box-fill > div {
	background-image: linear-gradient(315deg, #E741B9 0%, #FEB553 100%);
}

.mv_bi_nv .box-content {
	border-color: #201C1B;
	border-style: solid;
	border-width: 4px;
}

.mv_bi_nv .box-content, .mv_bi_nv .box-shadow, .mv_bi_nv .box-highlight, .mv_bi_nv .box-fill,
.mv_bi_nv .box-content > div, .mv_bi_nv .box-shadow > div, .mv_bi_nv .box-highlight > div, .mv_bi_nv .box-fill > div {
	border-radius: 7px;
}


.mv_bi_nv .box-shadow {
	filter: blur(4px);
}

.mv_bi_nv .box-shadow > div {
	background-color: #1AB69A;
}

.mv_bi_nv .box-highlight {
	filter: blur(2px);
}

.mv_bi_nv .box-highlight > div {
	background-color: #E1BE51;
}

.mv_bi_nv .box-block,
.mv_bi_nv .box-block a {
	font-family: "Spectral";
	color: #2C2929FF
}

/* clean cut */
.mv_bi_cc.header {
  color: #3B302DFF;
  text-shadow: 2px 2px #F2F2F2FF;
  font-family: "Spectral";
}
.mv_bi_cc.header > div.summary {
  font-family: "Spectral";
}

.mv_bi_cc .title-fill > div {
  background-color: #FAFAFA;
}

.mv_bi_cc .title-content {
  border-color: #010000FF;
  border-style: solid;
  border-width: 1px;
}


.mv_bi_cc .title-shadow {
  display: none;
}

.mv_bi_cc .title-highlight {
  display: none;
}

.mv_bi_cc .title-block,
.mv_bi_cc .title-block a {
  font-family: "Spectral";
  color: #3A231B
}

.mv_bi_cc .box-fill > div {
  background-color: #F2F2F2FF;
}

.mv_bi_cc .box-content {
  border-color: #201C1B;
  border-style: dotted;
  border-width: 1px;
}

.mv_bi_cc .box-shadow {
  display: none;
}

.mv_bi_cc .box-highlight {
  display: none;
}

.mv_bi_cc .box-block,
.mv_bi_cc .box-block a {
  font-family: "Spectral";
  color: #3B302DFF;
}

/* ancient graph */
.mv_bi_ag.header {
  color: #500A00FF;
  text-shadow: 2px 2px #F1D1B3FF;
  font-family: "Times New Roman";
}
.mv_bi_ag.header > div.summary {
  font-family: "Times New Roman";
}

.mv_bi_ag .title-fill > div {
  background-color: #FFF0E8FF;
}

.mv_bi_ag .title-content {
  border-color: #CB564A;
  border-style: solid;
  border-width: 1px;
}

.mv_bi_ag .title-content, .mv_bi_ag .title-shadow, .mv_bi_ag .title-highlight, .mv_bi_ag .title-fill,
.mv_bi_ag .title-content > div, .mv_bi_ag .title-shadow > div, .mv_bi_ag .title-highlight > div, .mv_bi_ag .title-fill > div {
  border-radius: 2px;
}

.mv_bi_ag .title-shadow > div {
  background-color: #D694E7FF;
}

.mv_bi_ag .title-highlight {
  display: none;
}

.mv_bi_ag .title-block,
.mv_bi_ag .title-block a {
  font-family: "Times New Roman";
  color: #500A00FF
}

.mv_bi_ag .box-fill {
  background-color: #FCF2DFFF;
}

.mv_bi_ag .box-fill > div {
  mask-image: var(--mvpat-hatch);
  -webkit-mask-image: var(--mvpat-hatch);
  background-color: #F1D1B3FF;
}

.mv_bi_ag .box-content {
  border-color: #D9864A;
  border-style: solid;
  border-width: 2px;
}

.mv_bi_ag .box-content, .mv_bi_ag .box-shadow, .mv_bi_ag .box-highlight, .mv_bi_ag .box-fill,
.mv_bi_ag .box-content > div, .mv_bi_ag .box-shadow > div, .mv_bi_ag .box-highlight > div, .mv_bi_ag .box-fill > div {
  border-radius: 5px;
}


.mv_bi_ag .box-shadow > div {
  background-color: #DF95A7;
}

.mv_bi_ag .box-highlight {
  display: none;
}

.mv_bi_ag .box-block,
.mv_bi_ag .box-block a {
  font-family: "Times New Roman";
  color: #410000FF
}

/* forbidden */
.mv_bi_fb.header {
  color: #640249FF;
  text-shadow: 2px 2px #FEB553;
  font-family: "Red Rose";
}
.mv_bi_fb.header > div.summary {
  font-family: "Red Rose";
}

.mv_bi_fb .title-fill > div {
  background-color: #21002DFF;
}

.mv_bi_fb .title-content {
  border-color: #640249FF;
  border-style: solid;
  border-width: 1px;
}


.mv_bi_fb .title-shadow {
  filter: blur(1px);
}

.mv_bi_fb .title-shadow > div {
  background-color: #DD3977;
}

.mv_bi_fb .title-highlight {
  display: none;
}

.mv_bi_fb .title-block,
.mv_bi_fb .title-block a {
  font-family: "Red Rose";
  color: #CB358D;
}

.mv_bi_fb .box-fill > div {
  background-image: linear-gradient(0deg, #140410FF 0%, #3B092AFF 100%);
}

.mv_bi_fb .box-content {
  border-color: #F0005BFF;
  border-style: solid;
  border-width: 1px;
}

.mv_bi_fb .box-shadow {
  filter: blur(1px);
}

.mv_bi_fb .box-shadow > div {
  background-color: #DD5787FF;
}

.mv_bi_fb .box-highlight {
  display: none;
}

.mv_bi_fb .box-block,
.mv_bi_fb .box-block a {
  font-family: "Red Rose";
  color: #D63AA7;
}

/* prismakick */

.mv_bi_pk.header {
  color: #000000;
  text-shadow: 2px 2px #99F6F7;
  font-family: "Times New Roman";
}
.mv_bi_pk.header > div.summary {
  font-family: "Maitree";
}

.mv_bi_pk .title-fill > div {
  background-color: #FFFFFF;
}

.mv_bi_pk .title-content {
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
}
  

.mv_bi_pk .title-shadow {
  filter: blur(4px);
}
  
.mv_bi_pk .title-shadow > div {
  background-color: #EC65C7;
}

.mv_bi_pk .title-highlight {
  filter: blur(4px);
}

.mv_bi_pk .title-highlight > div {
  background-color: #99F6F7;
}

.mv_bi_pk .title-block,
.mv_bi_pk .title-block a {
  font-family: "Times New Roman";
  color: #000000
}

.mv_bi_pk .box-fill > div {
  background-image: linear-gradient(0deg, #EBDAAF 0%, #00FFFF1A 100%);
}

.mv_bi_pk .box-content {
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
}


.mv_bi_pk .box-shadow {
  filter: blur(4px);
}

.mv_bi_pk .box-shadow > div {
  background-color: #EC65C7;
}

.mv_bi_pk .box-highlight {
  filter: blur(4px);
}

.mv_bi_pk .box-highlight > div {
  background-color: #99F6F7;
}

.mv_bi_pk .box-block,
.mv_bi_pk .box-block a {
  font-family: "Maitree";
  color: #000000
}

/* glitched purple */
.mv_bi_gp.header {
  color: #3111B1;
  text-shadow: 2px 2px #B8A6FE;
  font-family: "Contrail One";
}
.mv_bi_gp.header > div.summary {
  font-family: "Contrail One";
}

.mv_bi_gp .title-fill > div {
  background-color: #D1E0F4;
}

.mv_bi_gp .title-content {
  border-color: #3E0EFF;
  border-style: solid;
  border-width: 1px;
}


.mv_bi_gp .title-shadow > div {
  background-color: #B8A6FE;
}

.mv_bi_gp .title-highlight {
  display: none;
}

.mv_bi_gp .title-block,
.mv_bi_gp .title-block a {
  font-family: "Contrail One";
  color: #3400FF
}

.mv_bi_gp .box-fill > div {
  background-image: linear-gradient(45deg, #EDEDFF00 0%, #D1EAF0 100%);
}

.mv_bi_gp .box-content {
  border-color: #3E0EFF;
  border-style: solid;
  border-width: 1px;
}


.mv_bi_gp .box-shadow > div {
  background-color: #B8A6FE;
}

.mv_bi_gp .box-highlight {
  display: none;
}

.mv_bi_gp .box-block,
.mv_bi_gp .box-block a {
  font-family: "Contrail One";
  color: #3111B1
}

/* work order */
.mv_bi_wo.header {
  color: #640249FF;
  text-shadow: 2px 2px #FFB884FF;
  font-family: "Roboto Slab";
}

.mv_bi_wo.header > div.summary {
  font-family: "Roboto Slab";
}

.mv_bi_wo .title-fill > div {
  background-color: #FFC100FF;
}

.mv_bi_wo .title-content {
  border-color: #640249FF;
  border-style: solid;
  border-width: 2px;
}

.mv_bi_wo .title-shadow > div {
  background-color: #B42222FF;
}

.mv_bi_wo .title-highlight {
  display: none;
}

.mv_bi_wo .title-block,
.mv_bi_wo .title-block a {
  font-family: "Roboto Slab";
  color: #3A231B
}

.mv_bi_wo .box-fill > div {
  background-image: linear-gradient(0deg, #FF5C00FF 0%, #FFD913FF 100%);
}

.mv_bi_wo .box-content {
  border-color: #201C1B;
  border-style: solid;
  border-width: 2px;
}

.mv_bi_wo .box-shadow > div {
  background-color: #FFB884FF;
}

.mv_bi_wo .box-highlight {
  display: none;
}

.mv_bi_wo .box-block,
.mv_bi_wo .box-block a {
  font-family: "Roboto Slab";
  color: #12010EFF
}

/* sadness */

        .mv_bi_sad.header {
          color: #127042;
          text-shadow: 2px 2px #CBC7F1;
          font-family: "Arial";
        }
        .mv_bi_sad.header > div.summary {
          font-family: "Droid Sans";
        }
      
        .mv_bi_sad .title-fill > div {
          background-image: linear-gradient(0deg, #357B71 0%, #58C3A2 100%);
        }
      
        .mv_bi_sad .title-content {
          border-color: #5F30E6FF;
          border-style: solid;
          border-width: 1px;
        }
        
      
        .mv_bi_sad .title-shadow > div {
          background-image: linear-gradient(0deg, #000000 0%, #6E30E7 100%);
        }
      
        .mv_bi_sad .title-highlight > div {
          background-image: linear-gradient(0deg, #127042 0%, #29E7D02F 100%);
        }
      
      .mv_bi_sad .title-block,
      .mv_bi_sad .title-block a {
        font-family: "Arial";
        color: #300F5AFF
      }
    
        .mv_bi_sad .box-fill > div {
          background-image: linear-gradient(90deg, #000000 0%, #6E30E7 100%);
        }
      
        .mv_bi_sad .box-content {
          border-color: #6730B7;
          border-style: solid;
          border-width: 1px;
        }
        
      
        .mv_bi_sad .box-shadow > div {
          background-color: #CBC7F1;
        }
      
        .mv_bi_sad .box-highlight {
          display: none;
        }
      
      .mv_bi_sad .box-block,
      .mv_bi_sad .box-block a {
        font-family: "Droid Sans";
        color: #FAFAFA
      }
   
/* teddybear */
        .mv_bi_tdy.header {
          color: #000000;
          text-shadow: 2px 2px #FFB0E0FF;
          font-family: "Lobster";
        }
        .mv_bi_tdy.header > div.summary {
          font-family: "Crimson Text";
        }
      
          .mv_bi_tdy .title-fill {
            
            background-color: #FFB0E0FF;
          
          }
        
        .mv_bi_tdy .title-fill > div {
          
            mask-image: var(--mvpat-hatch);
            -webkit-mask-image: var(--mvpat-hatch);
            background-color: #FFFFFFFF;
          
        }
      
        .mv_bi_tdy .title-shadow > div {
          background-color: #FAFAFA;
        }
      
          .mv_bi_tdy .title-highlight {
            filter: blur(8px);
          }
        
        .mv_bi_tdy .title-highlight > div {
          background-color: #FFFFFFFF;
        }
      
      .mv_bi_tdy .title-block,
      .mv_bi_tdy .title-block a {
        font-family: "Lobster";
        color: #FAFAFAFF
      }
    
        .mv_bi_tdy .box-fill > div {
          background-image: linear-gradient(315deg, #DAF2FFFF 0%, #FFB4D800 100%);
        }
      
        .mv_bi_tdy .box-content {
          border-color: #FFBEDDFF;
          border-style: dotted;
          border-width: 4px;
        }
        
          .mv_bi_tdy .box-content, .mv_bi_tdy .box-shadow, .mv_bi_tdy .box-highlight, .mv_bi_tdy .box-fill,
          .mv_bi_tdy .box-content > div, .mv_bi_tdy .box-shadow > div, .mv_bi_tdy .box-highlight > div, .mv_bi_tdy .box-fill > div {
            border-radius: 8px;
          }
        
      
          .mv_bi_tdy .box-shadow {
            filter: blur(2px);
          }
        
        .mv_bi_tdy .box-shadow > div {
          background-color: #FFE4F4FF;
        }
      
        .mv_bi_tdy .box-highlight {
          display: none;
        }
      
      .mv_bi_tdy .box-block,
      .mv_bi_tdy .box-block a {
        font-family: "Crimson Text";
        color: #FF76C8FF
      }
/* cinni */

        .mv_bi_ci.header {
          color: #000000;
          text-shadow: 2px 2px #EFB4E6FF;
          font-family: "Verdana";
        }
        .mv_bi_ci.header > div.summary {
          font-family: "Verdana";
        }
      
        .mv_bi_ci .title-fill > div {
          background-image: linear-gradient(90deg, #8239DEFF 0%, #D6ECF3FF 100%);
        }
      
        .mv_bi_ci .title-shadow > div {
          background-color: #FAFAFAFF;
        }
      
        .mv_bi_ci .title-highlight {
          display: none;
        }
      
      .mv_bi_ci .title-block,
      .mv_bi_ci .title-block a {
        font-family: "Verdana";
        color: #D7F8FFFF
      }
    
        .mv_bi_ci .box-fill > div {
          background-color: #E3F6FFFF;
        }
      
        .mv_bi_ci .box-content {
          border-color: #6E45FFFF;
          border-style: solid;
          border-width: 1px;
        }
        
      
          .mv_bi_ci .box-shadow {
            filter: blur(2px);
          }
        
        .mv_bi_ci .box-shadow > div {
          background-image: linear-gradient(0deg, #EFB4E6FF 0%, #00FFB31A 100%);
        }
      
        .mv_bi_ci .box-highlight {
          display: none;
        }
      
      .mv_bi_ci .box-block,
      .mv_bi_ci .box-block a {
        font-family: "Verdana";
        color: #A245FFFF
      }

/* snufkin */
        .mv_bi_sn.header {
          color: #6a6939;
          text-shadow: 2px 2px #F5EEC1;
          font-family: "Comfortaa";
        }
        .mv_bi_sn.header > div.summary {
          font-family: "Roboto Mono";
        }
      
        .mv_bi_sn .title-fill > div {
          background-color: #B68B26FF;
        }
      
        .mv_bi_sn .title-content {
          border-color: #0F5B0FFF;
          border-style: dotted;
          border-width: 2px;
        }
        
          .mv_bi_sn .title-content, .mv_bi_sn .title-shadow, .mv_bi_sn .title-highlight, .mv_bi_sn .title-fill,
          .mv_bi_sn .title-content > div, .mv_bi_sn .title-shadow > div, .mv_bi_sn .title-highlight > div, .mv_bi_sn .title-fill > div {
            border-radius: 20px;
          }
        
      
        .mv_bi_sn .title-shadow {
          display: none;
        }
      
        .mv_bi_sn .title-highlight {
          display: none;
        }
      
      .mv_bi_sn .title-block,
      .mv_bi_sn .title-block a {
        font-family: "Comfortaa";
        color: #F5EEC1FF
      }
    
          .mv_bi_sn .box-fill {
            
            background-color: #6a6939;
          
          }
        
        .mv_bi_sn .box-fill > div {
          
            mask-image: var(--mvpat-confetti);
            -webkit-mask-image: var(--mvpat-confetti);
            background-color: #C67F35FF;
          
        }
      
        .mv_bi_sn .box-content {
          border-color: #F5EEC1;
          border-style: solid;
          border-width: 2px;
        }
        
          .mv_bi_sn .box-content, .mv_bi_sn .box-shadow, .mv_bi_sn .box-highlight, .mv_bi_sn .box-fill,
          .mv_bi_sn .box-content > div, .mv_bi_sn .box-shadow > div, .mv_bi_sn .box-highlight > div, .mv_bi_sn .box-fill > div {
            border-radius: 2px;
          }
        
      
        .mv_bi_sn .box-shadow > div {
          background-color: #94DBBDFF;
        }
      
        .mv_bi_sn .box-highlight {
          display: none;
        }
      
      .mv_bi_sn .box-block,
      .mv_bi_sn .box-block a {
        font-family: "Roboto Mono";
        color: #EEEEEEFF
      }

/* soleil */
        .mv_bi_ub.header {
          color: #3A231B;
          text-shadow: 2px 2px #F5DDC1;
          font-family: "Helvetica Neue";
        }
        .mv_bi_ub.header > div.summary {
          font-family: "Nunito";
        }
      
        .mv_bi_ub .title-fill > div {
          background-image: linear-gradient(90deg, #E2B617 0%, #FFAF663E 100%);
        }
      
        .mv_bi_ub .title-content {
          border-color: #FAFAFA;
          border-style: solid;
          border-width: 1px;
        }
        
          .mv_bi_ub .title-content, .mv_bi_ub .title-shadow, .mv_bi_ub .title-highlight, .mv_bi_ub .title-fill,
          .mv_bi_ub .title-content > div, .mv_bi_ub .title-shadow > div, .mv_bi_ub .title-highlight > div, .mv_bi_ub .title-fill > div {
            border-radius: 20px;
          }
        
      
          .mv_bi_ub .title-shadow {
            filter: blur(4px);
          }
        
        .mv_bi_ub .title-shadow > div {
          background-color: #DF95A7FF;
        }
      
        .mv_bi_ub .title-highlight > div {
          background-color: #FAFAFA;
        }
      
      .mv_bi_ub .title-block,
      .mv_bi_ub .title-block a {
        font-family: "Helvetica Neue";
        color: #FAFAFA
      }
    
        .mv_bi_ub .box-fill > div {
          background-color: #FAFAFA;
        }
      
        .mv_bi_ub .box-content {
          border-color: #E1BE51;
          border-style: solid;
          border-width: 4px;
        }
        
      
          .mv_bi_ub .box-shadow {
            filter: blur(4px);
          }
        
        .mv_bi_ub .box-shadow > div {
          background-image: linear-gradient(0deg, #E2B617 0%, #FFAF663E 100%);
        }
      
          .mv_bi_ub .box-highlight {
            filter: blur(8px);
          }
        
        .mv_bi_ub .box-highlight > div {
          background-image: linear-gradient(0deg, #F5EEC1 0%, #F5DDC1 100%);
        }
      
      .mv_bi_ub .box-block,
      .mv_bi_ub .box-block a {
        font-family: "Nunito";
        color: #3A231B
      }
